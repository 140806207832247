import {
  PageHeader,
  Button,
  Empty,
  InputNumber,
  Typography,
  Form,
  Descriptions,
  Tooltip,
  Col,
  Row,
  Input,
} from "antd";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { FakeInput } from "./FakeInput";

import { CloseOutlined } from "@ant-design/icons";
import {
  removeItem,
  setItemDiscount,
  setItemMarge,
  setItemQuantity,
  setTotal,
  setAchat,
  setTotalDiscount,
  setUnitPriceHT,
  setUnitPriceTTC,
  setSelectedItem,
  setDetails,
  setFODEC,
  setTitle,
} from "../Actions/ItemsSelectionAtions";
import { toGetCode } from "../Pages/Compte/Api";
import history from "../Utils/History";
import ArticleModal from "../Pages/Gestions/ArticleModal";
import {
  toAddOrUpdateArticle,
  toGetArticleDetail,
} from "../Pages/Gestions/Api";
import { successMsg } from "../Utils/Utils";

const ItemsSelection = ({
  achat,
  items,
  discount,
  total,
  totalDiscount,
  selectedItem,
  fodec,
  displayMarge,
  devis,
  ...props
}) => {
  const [quantityFocused, setQuantityFocused] = useState(false);
  const [totalFocused, setTotalFocused] = useState(false);
  const [disccountTotalFocused, setDisccountTotalFocused] = useState(false);
  const [pDiscountFocused, setPDiscountFocused] = useState(null);
  const [pMargeFocused, setPMargeFocused] = useState(null);
  const [uPriceHtFocused, setUPriceHtFocused] = useState(null);
  const [uPriceTTCFocused, setUPriceTTCFocused] = useState(null);
  const [percentage, setPercentage] = useState(0);
  const [fodecPercentage, setFodecPercentage] = useState(0);
  const [createModalVisible, setCreateModalVisible] = useState(false);

  const [articleDetails, setArticleDetails] = useState({
    title: "",
    reference: "",
    marque: "",
    type: "Origine",
    categorie: "",
  });

  useEffect(() => {
    getTotalMarge();
    toGetCode().then((res) => {
      setPercentage(res.percentage);
      setFodecPercentage(res.percentageFodec);
    });
  }, [items, total, achat, selectedItem]);

  const exitCreateModal = () => {
    setCreateModalVisible(false);
  };

  const saveArticleItem = (id, article) => {
    toAddOrUpdateArticle(id, article).then((newArticle) => {
      props.setDetails(newArticle);
      successMsg("Article modifié avec succés");

      setCreateModalVisible(false);
    });
  };

  const toEdit = (e, selected) => {
    e.preventDefault();

    if (e.type == "contextmenu") {
      return;
    }

    toGetArticleDetail(selected.article.id).then((article) => {
      setArticleDetails({
        id: article.id,
        titre: article.s__firstTitle,
        titre2: article.s__secondTitle,
        reference: article.s__reference,
        code: article.s__barCode,
        type: article.s__type,
        unite: article.unite,
        marque: article.marqueTitle,
        categorie: article.categoryTitle,
        emplacement:
          null != article.emplacement ? article.emplacement.title : "",
        seuil: article.l__seuil,
        tva: article.tva,
        prixAchatHT: article.l__prixAchatHT,
        prixAchatTTC: article.l__prixAchatTTC,
        data: article.data,
        b__tecdoc: article.b__tecdoc,
        marge: article.l__marge,
        margeValue: article.l__margeValue,
        prixVenteHT: article.l__prixVenteHT,
        prixVenteTTC: article.l__prixVenteTTC,

        margeGros: article.l__margeGros,
        margeGrosValue: article.l__margeGrosValue,
        prixVenteGrosHT: article.l__prixVenteGrosHT,
        prixVenteGrosTTC: article.l__prixVenteGrosTTC,
      });
      setCreateModalVisible(true);
    });
  };

  const togglePDiscountFocused = (ref) => {
    setPDiscountFocused(ref);
    if (ref && ref != selectedItem.ref) {
      props.setSelectedItem(ref);
    }
  };

  const togglePMargeFocused = (ref) => {
    setPMargeFocused(ref);
    if (ref && ref != selectedItem.ref) {
      props.setSelectedItem(ref);
    }
  };

  const toggleQuantityFocused = (ref) => {
    setQuantityFocused(ref);
    if (ref && ref != selectedItem.ref) {
      props.setSelectedItem(ref);
    }
  };

  const toggleUPriceHTFocused = (ref) => {
    setUPriceHtFocused(ref);
    if (ref && ref != selectedItem.ref) {
      props.setSelectedItem(ref);
    }
  };

  const toggleUPriceTTCFocused = (ref) => {
    setUPriceTTCFocused(ref);
    if (ref && ref != selectedItem.ref) {
      props.setSelectedItem(ref);
    }
  };

  const toggleTotalFocused = () => {
    setTotalFocused(!totalFocused);
  };

  const toggleDiscountTotalFocused = () => {
    setDisccountTotalFocused(!disccountTotalFocused);
  };

  const totalDiscountIsHigh = () => {
    const totalAchat = items.reduce((a, b) => b.prix_achat + a, 0);

    const min = (totalAchat * (100 + percentage)) / 100;
    return total < min && achat;
  };

  const getTotalMarge = () => {
    var margeTotal = 0;
    var prix_achat = items.reduce((a, b) => a + b.prix_achat * b.quantity, 0);
    if (items.length > 0) {
      margeTotal = Math.round(((total - prix_achat) / prix_achat) * 100);

      if (margeTotal > 0) {
        props.getMarge(parseInt(margeTotal));
      } else {
        props.getMarge(0);
      }
    } else {
      props.getMarge(-1);
    }
  };

  const getTotalHT = () => {
    var total = items.reduce(
      (a, b) => a + ((b.uPriceHt * (100 - b.discount)) / 100) * b.quantity,
      0
    );
    return total;
  };

  const getTotalTTC = () => {
    var total = items.reduce(
      (a, b) => a + ((b.uPriceTTC * (100 - b.discount)) / 100) * b.quantity,
      0
    );

    const fodecValue = fodec ? (getTotalHT() * fodecPercentage) / 100 : 0;
    return total + fodecValue;
  };

  return items != null && items.length > 0 ? (
    <>
      <ArticleModal
        saveArticle={saveArticleItem}
        details={articleDetails}
        createModalVisible={createModalVisible}
        closeCreateModal={exitCreateModal}
      />
      {null != selectedItem && (
        <div style={{ backgroundColor: devis ? "#f5f3d5" : "" }}>
          <div className="site-page-header-ghost-wrapper" style={{}}>
            <Descriptions size="small" column={6}>
              <Descriptions.Item label="Réf" span={1}>
                <Typography.Title level={5}>
                  <Tooltip
                    title={selectedItem.uLastPaied + ""}
                    color="#58c5cc"
                    placement="bottom"
                  >
                    {selectedItem.ref}
                  </Tooltip>
                </Typography.Title>
              </Descriptions.Item>
              <Descriptions.Item label="" span={2}>
                {achat ? (
                  <Typography.Title level={5}>
                    <Typography.Link>{selectedItem.titre}</Typography.Link>
                  </Typography.Title>
                ) : (
                  <Input
                    style={{ width: "100%" }}
                    onChange={(e) =>
                      props.setTitle(e.target.value, selectedItem)
                    }
                    value={selectedItem.titre}
                  />
                )}
              </Descriptions.Item>
              <Descriptions.Item label="Stock actuel" span={1}>
                <Typography.Title level={5}>
                  <Typography.Link>
                    <span
                      className={
                        selectedItem.qte_stock <= 0
                          ? "auto--color-danger"
                          : selectedItem.qte_stock < selectedItem.seuil
                          ? "auto--color-warning"
                          : "auto--color-success"
                      }
                    >
                      {selectedItem.qte_stock}
                    </span>
                  </Typography.Link>
                </Typography.Title>
              </Descriptions.Item>
              <Descriptions.Item label="P.vte HT" span={1}>
                <Typography.Title level={5}>
                  <Typography.Link>
                    {selectedItem.prix_unitaire.toFixed(3)}
                  </Typography.Link>
                </Typography.Title>
              </Descriptions.Item>
            </Descriptions>
          </div>
        </div>
      )}
      <div className="table-wrapper">
        <table className="ant-table">
          <thead className="ant-table-thead">
            <tr>
              <th className="ant-table-cell">Prix U HT</th>
              <th className="ant-table-cell">Prix U TTC</th>
              <th className="ant-table-cell" style={{ width: 90 }}>
                Quantité
              </th>
              <th className="ant-table-cell" style={{ width: 100 }}>
                Remise
              </th>
              {displayMarge && (
                <th className="ant-table-cell" style={{ width: 100 }}>
                  Marge
                </th>
              )}
              {achat && <th className="ant-table-cell">P.V HT</th>}
              <th className="ant-table-cell">Total </th>
              <th
                className="ant-table-cell"
                style={{ paddingRight: 0, paddingLeft: 0 }}
              ></th>
            </tr>
          </thead>
          <tbody className="ant-table-tbody">
            {items.map((item, i) => (
              <tr
                key={i}
                className={
                  item.id === selectedItem.id
                    ? achat
                      ? "selected-line-item-achat"
                      : "selected-line-item-vente"
                    : ""
                }
              >
                <td className="ant-table-cell">
                  <FakeInput
                    condition={uPriceHtFocused === item.ref}
                    value={parseFloat(item.uPriceHt).toFixed(3)}
                    onBlur={() => toggleUPriceHTFocused(null)}
                    min={0}
                    onClick={() => toggleUPriceHTFocused(item.ref)}
                    onChange={(value) => props.setUnitPriceHT(value, item)}
                  />
                </td>
                <td className="ant-table-cell">
                  <FakeInput
                    condition={uPriceTTCFocused === item.ref}
                    value={parseFloat(item.uPriceTTC).toFixed(3)}
                    onBlur={() => toggleUPriceTTCFocused(null)}
                    min={0}
                    onClick={() => toggleUPriceTTCFocused(item.ref)}
                    onChange={(value) => props.setUnitPriceTTC(value, item)}
                  />
                </td>
                <td className="ant-table-cell" style={{ width: 90 }}>
                  <FakeInput
                    className="tight"
                    condition={quantityFocused === item.ref}
                    value={item.quantity}
                    onBlur={() => toggleQuantityFocused(null)}
                    onClick={() => toggleQuantityFocused(item.ref)}
                    onChange={(value) => props.setItemQuantity(value, item)}
                  />
                </td>
                <td className="ant-table-cell" style={{ width: 100 }}>
                  <FakeInput
                    className="tight"
                    condition={pDiscountFocused === item.ref}
                    value={parseFloat(item.discount).toFixed(1)}
                    prefix="%"
                    onBlur={() => {
                      togglePDiscountFocused(null);
                    }}
                    onClick={() => togglePDiscountFocused(item.ref)}
                    onChange={(value) => props.setItemDiscount(value, item)}
                    min={0}
                    max={100}
                  />
                </td>
                {/*marge*/}
                {displayMarge && (
                  <td className="ant-table-cell" style={{ width: 100 }}>
                    <FakeInput
                      className="tight"
                      condition={pMargeFocused === item.ref}
                      value={
                        isNaN(item.marge)
                          ? 0
                          : parseFloat(item.marge).toFixed(1)
                      }
                      prefix={item.margeValue}
                      onBlur={() => {
                        togglePMargeFocused(null);
                      }}
                      onClick={() => togglePMargeFocused(item.ref)}
                      onChange={(value) => props.setItemMarge(value, item)}
                      min={0}
                    />
                  </td>
                )}
                {achat && (
                  <td className="ant-table-cell">
                    {((item.uPriceHt * (100 + item.marge)) / 100).toFixed(3)}
                  </td>
                )}
                <td className="ant-table-cell">{item.total.toFixed(3)}</td>

                <td
                  className="ant-table-cell"
                  style={{ paddingRight: 10, paddingLeft: 10 }}
                >
                  <Button
                    icon={<CloseOutlined />}
                    onClick={() => props.removeItem(item)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div style={{ backgroundColor: "#f0f2f5" }}>
        <div
          className="site-page-header-ghost-wrapper"
          style={{
            backgroundColor: "#f0f2f5",
            border: "2px solid #f0f2f5",
            padding: "1vh 0",
            borderRadius: "25px",
          }}
        >
          <PageHeader ghost={false}>
            <Row size="small" column={1}>
              <Col span="5">
                <span>Remise Total %</span>
              </Col>
              <Col span="4">
                <Descriptions.Item label="% Remise">
                  <Typography.Title level={5}>
                    <Typography.Link>
                      <Form.Item name="total" className="smtv-input">
                        {disccountTotalFocused ? (
                          <InputNumber
                            defaultValue={parseFloat(totalDiscount).toFixed(3)}
                            ref={(ref) => ref && ref.focus()}
                            onBlur={toggleDiscountTotalFocused}
                            onChange={props.setTotalDiscount}
                            style={{
                              backgroundColor: "white",
                            }}
                          />
                        ) : (
                          <div
                            className="ant-input-number"
                            onClick={toggleDiscountTotalFocused}
                          >
                            <div className="ant-input-number-input-wrap">
                              <input
                                style={{
                                  backgroundColor: "white",
                                }}
                                className="ant-input-number-input"
                                value={totalDiscount.toFixed(3) + " %"}
                                readOnly
                              />
                            </div>
                          </div>
                        )}
                      </Form.Item>
                    </Typography.Link>
                  </Typography.Title>
                </Descriptions.Item>
              </Col>{" "}
            </Row>
            <Descriptions size="small" column={4}>
              <Descriptions.Item label="Remise">
                <Typography.Title level={5}>
                  <Typography.Link>
                    {parseFloat(discount).toFixed(2)} DT
                  </Typography.Link>
                </Typography.Title>
              </Descriptions.Item>
              <Descriptions.Item label="Net HT">
                <Typography.Title level={5}>
                  <Typography.Link>{getTotalHT().toFixed(3)}</Typography.Link>
                </Typography.Title>
              </Descriptions.Item>
              <Descriptions.Item label="Taxe">
                <Typography.Title level={5}>
                  <Typography.Link>
                    {(getTotalTTC() - getTotalHT()).toFixed(3)}
                  </Typography.Link>
                </Typography.Title>
              </Descriptions.Item>
              {fodec && (
                <Descriptions.Item label="FODEC">
                  <Typography.Title level={5}>
                    <Typography.Link>
                      {((getTotalHT() * 1) / 100).toFixed(3)}
                    </Typography.Link>
                  </Typography.Title>
                </Descriptions.Item>
              )}
              <Descriptions.Item label="Net TTC">
                <Typography.Title level={5}>
                  <Typography.Link>
                    <Form.Item name="total" className="smtv-input">
                      {totalFocused ? (
                        <InputNumber
                          defaultValue={(history.location.pathname ===
                            "/vente/facture/creer" && props.client.b__stamp
                            ? getTotalTTC() + 1
                            : getTotalTTC()
                          ).toFixed(3)}
                          ref={(ref) => ref && ref.focus()}
                          disabled
                          onBlur={toggleTotalFocused}
                          onChange={props.setTotal}
                          style={{
                            backgroundColor: totalDiscountIsHigh()
                              ? "#ffccc7"
                              : "white",
                          }}
                        />
                      ) : (
                        <div
                          className="ant-input-number"
                          onClick={toggleTotalFocused}
                        >
                          <div className="ant-input-number-input-wrap">
                            <input
                              disabled={true}
                              style={{
                                backgroundColor:
                                  !achat && totalDiscountIsHigh()
                                    ? "#ffccc7"
                                    : "white",
                              }}
                              className="ant-input-number-input"
                              value={`${(history.location.pathname ===
                                "/vente/facture/creer" && props.client.b__stamp
                                ? getTotalTTC() + 1
                                : getTotalTTC()
                              ).toFixed(3)} DT`}
                              readOnly
                            />
                          </div>
                        </div>
                      )}
                    </Form.Item>
                  </Typography.Link>
                </Typography.Title>
              </Descriptions.Item>
            </Descriptions>
          </PageHeader>
        </div>
      </div>
    </>
  ) : (
    <>
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: devis ? "#f5f3d5" : "",
        }}
      >
        <Empty description="Aucun article ajouté"></Empty>
      </div>
      <Row justify="end" gutter={8}>
        <Col xl={6} lg={8}>
          <Button block danger onClick={() => history.goBack()}>
            Retour
          </Button>
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = ({ itemSelectionState }) => {
  return {
    items: itemSelectionState.items,
    discount: itemSelectionState.discount,
    total: itemSelectionState.total,
    totalDiscount: itemSelectionState.totalDiscount,
    selectedItem: itemSelectionState.selectedItem,
    achat: itemSelectionState.achat,
    fodec: itemSelectionState.fodec,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setTotal: (total) => dispatch(setTotal(total)),
    setAchat: (achat) => dispatch(setAchat(achat)),
    setTotalDiscount: (totalDiscount) =>
      dispatch(setTotalDiscount(totalDiscount)),
    setSelectedItem: (ref) => dispatch(setSelectedItem(ref)),
    setDetails: (article) => dispatch(setDetails(article)),
    removeItem: (item) => dispatch(removeItem(item)),
    setItemQuantity: (quantity, item) =>
      dispatch(setItemQuantity(quantity, item)),
    setItemDiscount: (discount, item) =>
      dispatch(setItemDiscount(discount, item)),
    setItemMarge: (marge, item) => dispatch(setItemMarge(marge, item)),
    setUnitPriceHT: (value, item) => dispatch(setUnitPriceHT(value, item)),
    setTitle: (value, item) => dispatch(setTitle(value, item)),
    setUnitPriceTTC: (value, item) => dispatch(setUnitPriceTTC(value, item)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemsSelection);
